import { useEffect, useState, useContext, useRef } from "react";
import { IonContent } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAlbums } from "../actions/albumActions";
import { fetchGenres } from "../actions/genreActions";

import { fetchTracks } from "../actions/trackActions";
// import Footer from "../components/Footer";
// import Hero from "../components/Hero";
// import Hero2 from "../components/Hero2";
import Hero3 from "../components/Hero3";
// import Row0 from "../components/Row0";
import Row1 from "../components/Row1";
// import Row2 from "../components/Row2";
// import Row3 from "../components/Row3";
// import Row4 from "../components/Row4";
// import Row5 from "../components/Row5";
// import { SlidesExample } from "../components/SlidesExample";
import { PlatformContext } from "../contexts/PlatformContext";
// import InfoModal from "../components/InfoModal";
import {
  Album,
  // AlbumTracks,
  // MEDIA_STATUS,
  Track,
} from "../definitions/MediaInterface";
// import { slideOpts } from "../definitions/Settings";
import { RootStore } from "../store";
// import { ImageTrack } from "../utilities/MediaImages";
import { initialAlbumTracks } from "../definitions/Settings";
import Row1a from "../components/Row1a";
import Row1b from "../components/Row1b";
import Row1d from "../components/Row1d";
import Row1c from "../components/Row1c";
import Row1e from "../components/Row1e";
import PlayShuffle from "../components/PlayShuffle";
import queryString from "query-string";

const Home: React.FC<any> = ({ onScroll }) => {
  const { platform } = useContext(PlatformContext);

  // const [showInfoModal, setShowInfoModal] = useState(false)
  // const albumInfo = useSelector((state: RootStore) => state.albumInfo.items);
  // const genreInfo = useSelector((state: RootStore) => state.genreInfo.items);

  const allTracks = useSelector((state: RootStore) => state.allTracks.items);

  const [album, setAlbum] = useState<Album | null | undefined>();
  const [heroAlbum, setHeroAlbum] = useState<Album | null | undefined>();
  const [albumIndex, setAlbumIndex] = useState<any>(0);
  const [sharedTitle, setSharedTitle] = useState<string>("");
  const dispatch = useDispatch();

  const refTrackNo = useRef<number>(0);
  const isWeb = platform.name === "web";

  const [albumTracks, setAlbumTracks] = useState<any>(initialAlbumTracks);
  const [albumTracksPopular, setAlbumTracksPopular] =
    useState<any>(initialAlbumTracks);
  const [albumTracksGetStarted, setAlbumTracksGetStarted] =
    useState<any>(initialAlbumTracks);
  const [albumTracksTry, setAlbumTracksTry] = useState<any>(initialAlbumTracks);
  const [albumTracksFresh, setAlbumTracksFresh] =
    useState<any>(initialAlbumTracks);
  const [albumTracksToday, setAlbumTracksToday] =
    useState<any>(initialAlbumTracks);
  const [albumTracksTop10, setAlbumTracksTop10] =
    useState<any>(initialAlbumTracks);
  const [albumTracksShuffled, setAlbumTracksShuffled] =
    useState<any>(initialAlbumTracks);

  const slideOpts = {
    initialSlide: 0,
    // slidesPerView: 5,
    slidesPerView: "auto",
    speed: 400,
    // speed: 200,
    // loop:true,
    freeMode: true,
  };

  useEffect(() => {
    setAlbumTracks(initialAlbumTracks);
    // dispatch(fetchAlbums());
    dispatch(fetchGenres());
    dispatch(fetchTracks());
    let params = queryString.parse(window.location.search);
    if (params.title) {
      setSharedTitle(params.title + "");
    }
  }, []);

  useEffect(() => {
    console.log("allTracks >>>", allTracks)
    if (allTracks && allTracks.length > 0) {
      let newAlbumTracks: any = [];

      newAlbumTracks.push({
        id: 0,
        album_name: "Music Videos",
        album_info: "Music Videos",
        album_name_ar: "",
        album_info_ar: "",
        album_image: "",
        tracks: allTracks,
      });
      setAlbumTracks(newAlbumTracks);
      setAlbumTracksPopular([shuffleTracks(newAlbumTracks[0])]);
      setAlbumTracksGetStarted([shuffleTracks(newAlbumTracks[0])]);
      setAlbumTracksTry([shuffleTracks(newAlbumTracks[0])]);
      setAlbumTracksFresh([shuffleTracks(newAlbumTracks[0])]);
      setAlbumTracksToday([shuffleTracks(newAlbumTracks[0])]);
      setAlbumTracksTop10([shuffleTracks(newAlbumTracks[0])]);
      setAlbumTracksShuffled([shuffleTracks(newAlbumTracks[0])]);

      if (sharedTitle) {
        const track = allTracks?.filter((track: any) => {
          return (
            track.name.toLowerCase().replaceAll(" ", "-") ===
            sharedTitle.toLowerCase().replaceAll(" ", "-")
          );
        });
        // console.log("track>>>>", track);
        if (track && track.length > 0) {
          refTrackNo.current = parseInt("0" + track[0]?.id);
        }
      } else {
        randomizeTrackNo(allTracks.length);
      }
    }
    // else {
    //   alert();
    // }
  }, [allTracks, sharedTitle]);

  const randomizeTrackNo = (max: number) => {
    const min = 1;
    let rand = parseInt((min + Math.random() * (max - min)).toFixed(0)) - 1;
    // alert(rand+ ' '+ min+ ' '+ max);
    if (rand <= min) {
      rand = min;
    }
    // console.log("rand,min,max>>>.", rand, min, max);
    refTrackNo.current = rand;
  };
  const shuffleTracks = (array: any) => {
    var newAlbum = JSON.parse(JSON.stringify(array));
    newAlbum.tracks.sort(() => Math.random() - 0.5);
    return newAlbum;
  };

  // const prevScrollY = useRef(0);
  // const [goingUp, setGoingUp] = useState(false);
  // const onScroll1 = (e:any) => {
  //   const currentScrollY = e.target.scrollTop;
  //   if (prevScrollY.current < currentScrollY && goingUp) {
  //     setGoingUp(false);
  //   }
  //   if (prevScrollY.current > currentScrollY && !goingUp) {
  //     setGoingUp(true);
  //   }
  //   prevScrollY.current = currentScrollY;
  //   // console.log(goingUp, currentScrollY);
  //   console.log('currentScrollY>>>>', goingUp, currentScrollY);
  // };

  return (
    <>
      {/* https://api.takeone.studio/media/haitham_yousif--mayhmni/haitham_yousif--mayhmni.jpg */}
      {/* {console.log('goingUp>>>>',goingUp)} */}
      <IonContent
        scrollEvents={true}
        // onIonScroll={(e) =>{onScroll1(e); onScroll(e.detail.scrollTop)}}
        onIonScroll={(e) => onScroll(e.detail.scrollTop)}
        fullscreen
        className="pad-10"
      >
        <div className="page_section">
          {refTrackNo.current && (
            // <Hero
            //   albumTracks={albumTracks}
            //   trackNo={refTrackNo.current}
            //   // changeAlbumIndex={(index: number) => setAlbumIndex(index)}
            // />
            // <Hero2 albumTracks={albumTracks} trackNo={refTrackNo.current} />
            <Hero3 albumTracks={albumTracks} trackNo={refTrackNo.current} />
          )}
          {console.log(
            "albumTracksPopular[albumIndex]>>>",
            albumTracksPopular[albumIndex]
          )}
          <div className="takeone-videos">
            {/* Popular on TakeOne */}
            <Row1
              heroAlbum={albumTracksPopular[albumIndex]}
              slideOpts={slideOpts}
              isTall={!isWeb}
            />
            {/* To get you started */}
            <Row1a
              heroAlbum={albumTracksGetStarted[albumIndex]}
              slideOpts={slideOpts}
              isTall={!isWeb}
            />
            {/* Try something else */}
            <Row1b
              heroAlbum={albumTracksTry[albumIndex]}
              slideOpts={slideOpts}
              isTall={!isWeb}
            />
            {/* Undecided on what to watch? */}
            <PlayShuffle
              heroAlbum={albumTracksShuffled[albumIndex]}
              slideOpts={slideOpts}
              isTall={!isWeb}
            />
            {/* Fresh new music */}
            <Row1c
              heroAlbum={albumTracksFresh[albumIndex]}
              slideOpts={slideOpts}
              isTall={true}
            />
            {/* Recommended for today */}
            <Row1d
              heroAlbum={albumTracksToday[albumIndex]}
              slideOpts={slideOpts}
              isTall={!isWeb}
            />
            {/* Top 10 in the United Arab Emirates */}
            <Row1e
              heroAlbum={albumTracksTop10[albumIndex]}
              slideOpts={slideOpts}
              isTall={true}
            />
            <br />
          </div>
        </div>
      </IonContent>

      {/* 
            Popular on TakeOne
            Continue Watching for Auric
            Trending Now
            Top 10 Music Videos in the United Arab Emirates Today
            Watch it again
            Top Picks for Auric
            My List

            Not sure what to watch?
            We'll shuffle everything on TakeOne and find things for you 
            to watch based on your tastes.
           |-----------------------|
           |  \  /--               |
           |   --   Play Something |
           |  /  \--               |
           |-----------------------|

           */}
    </>
  );
};

export default Home;
